<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <PackageForm></PackageForm>
          
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
            <div class="vx-card p-6 mb-6">
              <LocationInfo></LocationInfo>
            </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import PackageForm from './common/PackageForm';
import AdditionInfo from './common/AdditionInfo';
import LocationInfo from './common/LocationInfo';
import SeoInfo from './common/SeoInfo';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    PackageForm,
    AdditionInfo,
    LocationInfo,
    SeoInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'main_package/getFormObj',
    })
  },
  created() {
    this.clearForm();
  },
  methods: {
    ...mapActions('main_package', [
        'createPackage',
        'clearForm'
    ]),
    submitForm() {
      this.openLoading();
      this.createPackage(this.form)
        .then(async response => {
          this.closeLoading();
          this.$router.push('/packages/' + response.data.data.id + '/edit-image');
        }).catch(err => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
